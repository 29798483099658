import type { tag } from '~components/Tags'

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum MaritalStatus {
  Single = 'Single',
  Divorced = 'Divorced',
  Married = 'Married',
  Widowed = 'Widowed',
}

export enum Race {
  Chinese = 'Chinese',
  Malay = 'Malay',
  Indian = 'Indian',
  Caucasian = 'Caucasian',
  Others = 'Others',
}

export interface EventAttended {
  id: string
  name: string
  tags?: tag[]
  rating?: 1 | 2 | 3 | 4 | 5
  timestamp: number
}

export interface Activity {
  description: string
  timestamp: Date | string
}

export interface participant {
  name: string
  interests?: string[]
  eventsAttended?: EventAttended[]
  lastContacted?: number
  lastConsent?: number
  consentGiven?: boolean
  nric?: string
  email?: string
  mobileNumber?: string
  age?: number
  gender?: Gender | string
  maritalStatus?: MaritalStatus
  race?: Race | string
  secondaryRace?: Race
  activities?: Activity[]
}
