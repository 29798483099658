import { useState } from 'react'
import { Container, Flex, Text, VStack } from '@chakra-ui/react'

import { useAuth } from '~lib/auth'
import { useDesktopToast } from '~hooks/useDesktopToast'

import { LoginForm, LoginFormInputs } from '../components/LoginForm'
import { OtpForm, OtpFormInputs } from '../components/OtpForm'
import { ReactComponent as Illustration } from '../illustration.svg'

const TITLE = 'Easy attendees management and planning'

const HeroContainerStyle = {
  padding: 0,
  margin: 0,
  width: 'fit-content',
}

const FormStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: 'fit-content',
  maxWidth: '600px',

  '& form': {
    width: '100%',
  },
}

const HeaderStyle = {
  fontWeight: 600,
  fontSize: '40px',
  lineHeight: '48px',
  color: '#272D41',
  fontFamily: `'Inter', sans-serif`,
}

export const LoginPage = (): JSX.Element => {
  const { sendLoginOtp, verifyLoginOtp } = useAuth()
  const [email, setEmail] = useState<string>()
  const [otpPrefix, setOtpPrefix] = useState<string>()
  useDesktopToast()

  const handleSendOtp = async ({ email }: LoginFormInputs) => {
    const trimmedEmail = email.trim()
    const { prefix } = await sendLoginOtp({ email: trimmedEmail })
    setOtpPrefix(prefix)
    return setEmail(trimmedEmail)
  }

  const handleVerifyOtp = async ({ token }: OtpFormInputs) => {
    // Should not happen, since OtpForm component is only shown when there is
    // already an email state set.
    if (!email) {
      throw new Error('Something went wrong')
    }
    return verifyLoginOtp({ token, email })
  }

  const handleResendOtp = async () => {
    // Should not happen, since OtpForm component is only shown when there is
    // already an email state set.
    if (!email) {
      throw new Error('Something went wrong')
    }
    const { prefix } = await sendLoginOtp({ email })
    setOtpPrefix(prefix)
  }

  return (
    <Flex
      h="calc(100vh - 32px)" // Note: removing 32px (hardcoded) from the restricted govt masthead
      w="100vw"
    >
      <Container sx={HeroContainerStyle} h="full">
        <Illustration width="100%" height="100%" />
      </Container>
      <Container sx={FormStyle}>
        <VStack w="full" spacing="40px">
          <Text sx={HeaderStyle}>{TITLE}</Text>
          {!email || !otpPrefix ? (
            <LoginForm onSubmit={handleSendOtp} />
          ) : (
            <OtpForm
              otpPrefix={otpPrefix}
              email={email}
              onSubmit={handleVerifyOtp}
              onResendOtp={handleResendOtp}
            />
          )}
        </VStack>
      </Container>
      {/* <AppFooter links={FOOTER_LINKS} /> */}
    </Flex>
  )
}
