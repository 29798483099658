import React, { useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { Box, Flex, Icon, Text, VStack } from '@chakra-ui/react'

import { DROPDOWN_ITEM_HEIGHT, SIDEBAR_ICON_SIZE } from '~constants/styles'
import { isPathActive, sidebarButtonOnClick } from '~components/Sidebar/index'
import { SidebarButton } from '~components/Sidebar/SidebarButton'
import {
  ExtraSectionNavigationDropdownStyle,
  ExtraSectionNavigationStyle,
  ExtraSectionStyle,
  ExtraSectionTitleStyle,
  HideCollapseStyle,
} from '~components/Sidebar/styles'
import { ExtraSectionNavigationRenderProps } from '~components/Sidebar/types'

export const ExtraNavigation = ({
  title,
  isCollapsed,
  active,
  navigations,
  navigateFn,
}: ExtraSectionNavigationRenderProps): JSX.Element => {
  const [showNavigationDropdown, setShowNavigationDropdown] = useState(false)

  return (
    <VStack
      w="full"
      sx={{
        ...ExtraSectionStyle,
        borderTop: isCollapsed ? 'none' : '1px solid #E9EAEE',
      }}
      pt="8px"
    >
      <Flex
        w="full"
        sx={{
          position: 'absolute',
          top: 0,
          alignItems: 'center',
          opacity: isCollapsed ? 1 : 0,
          zIndex: isCollapsed ? 1 : 0,
          ...HideCollapseStyle,
        }}
      >
        <SidebarButton
          active={false}
          onClick={() => setShowNavigationDropdown(!showNavigationDropdown)}
          onBlur={() => setShowNavigationDropdown(false)}
          w="full"
        >
          <Icon
            as={BiDotsVerticalRounded}
            width={SIDEBAR_ICON_SIZE}
            height={SIDEBAR_ICON_SIZE}
          />
        </SidebarButton>
        <Box
          sx={{
            ...ExtraSectionNavigationDropdownStyle,
            ...HideCollapseStyle,
            opacity: showNavigationDropdown ? 1 : 0,
            visibility: showNavigationDropdown ? 'visible' : 'hidden',
          }}
        >
          <Text
            sx={{
              ...ExtraSectionTitleStyle,
              height: DROPDOWN_ITEM_HEIGHT,
              borderBottom: '1px solid #E9EAEE',
              padding: '0 16px',
            }}
          >
            {title}
          </Text>
          {navigations.map((navigation, i) => {
            return (
              <SidebarButton
                active={isPathActive(navigation.path, active)}
                onClick={sidebarButtonOnClick(
                  navigation.path,
                  active,
                  navigateFn,
                  navigation.params as object,
                )}
                key={`navigation-${i}`}
                height={DROPDOWN_ITEM_HEIGHT}
                sx={{ paddingRight: '16px', paddingLeft: '16px' }}
              >
                <Text sx={HideCollapseStyle}>{navigation.name}</Text>
              </SidebarButton>
            )
          })}
        </Box>
      </Flex>
      <VStack
        spacing="0"
        sx={{
          opacity: isCollapsed ? 0 : 1,
          visibility: isCollapsed ? 'hidden' : 'visible',
          pointerEvents: isCollapsed ? 'none' : 'auto',
          ...HideCollapseStyle,
          ...ExtraSectionNavigationStyle,
        }}
      >
        <Text sx={ExtraSectionTitleStyle}>{title}</Text>
        {navigations.map((navigation, i) => (
          <SidebarButton
            active={isPathActive(navigation.path, active)}
            onClick={sidebarButtonOnClick(
              navigation.path,
              active,
              navigateFn,
              navigation.params as object,
            )}
            key={`navigation-${i}`}
            size="sm"
          >
            <Text sx={HideCollapseStyle} opacity={isCollapsed ? 0 : 1}>
              {navigation.name}
            </Text>
          </SidebarButton>
        ))}
      </VStack>
    </VStack>
  )
}
