import { Text, TextProps } from '@chakra-ui/react'

const ErrorMessageStyle = {
  color: '#C05050',
  fontFamily: `'Inter', sans-serif`,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  position: 'relative',
  paddingLeft: '20px',

  '&::after': {
    content: "''",
    position: 'absolute',
    left: 0,
    width: '14px',
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url("data:image/svg+xml,%3Csvg stroke='%23C05050' fill='%23C05050' stroke-width='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'%3E%3C/path%3E%3C/svg%3E")`,
  },
}

export const ErrorMessage = ({ children }: TextProps) => {
  return <Text sx={ErrorMessageStyle}>{children}</Text>
}
