import React from 'react'

import { isEventOngoing, isEventUpcoming } from '~shared/utils/event'

import { COMPLETED, ONGOING, UPCOMING } from '~constants/events'
import { Tags } from '~components/Tags'

interface EventStatusTagProps {
  startTimestamp: Date | string
  endTimestamp: Date | string
}

export const EventStatusTag = ({
  startTimestamp,
  endTimestamp,
}: EventStatusTagProps) => {
  if (isEventOngoing(startTimestamp, endTimestamp)) {
    return (
      <Tags
        key={`tag-ongoing`}
        text={ONGOING}
        color="#F5FAFA"
        backgroundColor="#2D978C"
      />
    )
  } else if (isEventUpcoming(startTimestamp)) {
    return (
      <Tags
        key={`tag-upcoming`}
        text={UPCOMING}
        color="#6F4C04"
        backgroundColor="#FFDA68"
      />
    )
  } else {
    return (
      <Tags
        key={`tag-completed`}
        text={COMPLETED}
        color="#F9F9FB"
        backgroundColor="#5E6591"
      />
    )
  }
}
