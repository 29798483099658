import { useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { Button, ButtonProps, HStack, Icon } from '@chakra-ui/react'

import {
  GREY_HOVER_BG_COLOUR,
  HOVER_BG_COLOUR,
  PRIMARY_BLUE_COLOUR,
  PRIMARY_TEXT_COLOUR,
} from '~constants/styles'

const ButtonHoveredStyle = {
  transform: 'translateY(-2px)',
  boxShadow: 'var(--chakra-shadows-md)',
}

const BaseButtonStyle = {
  border: 'none',
  borderRadius: '4px',
  padding: '8px 16px',
  minWidth: 'fit-content',
  height: '40px',

  fontSize: '14px',
  fontWeight: 600,

  transitionProperty: 'transform, box-shadow',
  transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
  transitionDuration: '200ms',

  '&:disabled, &[disabled], &[aria-disabled=true], &[data-disabled]': {
    pointerEvents: 'none',
    backgroundColor: '#BABECB',
    opacity: 1,
  },
}

const PrimaryButtonStyle = {
  ...BaseButtonStyle,
  bgColor: PRIMARY_BLUE_COLOUR,
  color: 'white',

  '&:hover': {
    ...ButtonHoveredStyle,
    bgColor: PRIMARY_BLUE_COLOUR,
  },
}

const SecondaryButtonStyle = {
  ...BaseButtonStyle,
  bgColor: 'transparent',
  border: `1px solid ${PRIMARY_BLUE_COLOUR}`,
  color: PRIMARY_BLUE_COLOUR,

  '&:hover': {
    ...ButtonHoveredStyle,
    bgColor: HOVER_BG_COLOUR,
  },
}

const TertiaryButtonStyle = {
  ...BaseButtonStyle,
  bgColor: 'transparent',
  border: 'none',
  color: PRIMARY_TEXT_COLOUR,

  '&:hover': {
    bgColor: GREY_HOVER_BG_COLOUR,
  },
}

export const PrimaryButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      sx={{ ...PrimaryButtonStyle, ...props.sx }}
      iconSpacing="8px"
    />
  )
}

export const SecondaryButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      sx={{ ...SecondaryButtonStyle, ...props.sx }}
      iconSpacing="8px"
    />
  )
}

export const TertiaryButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      sx={{ ...TertiaryButtonStyle, ...props.sx }}
      iconSpacing="8px"
    />
  )
}

interface ButtonOptionsProps {
  name: string
  onClick: () => void
}

interface ButtonWithOptionsProps extends ButtonProps {
  buttonType: 'primary' | 'secondary'
  options?: ButtonOptionsProps[]
}

export const ButtonWithOptions = ({
  buttonType,
  ...props
}: ButtonWithOptionsProps) => {
  const [hovered, setHovered] = useState(false)

  const buttonStyles =
    buttonType === 'primary' ? PrimaryButtonStyle : SecondaryButtonStyle

  return (
    <HStack
      spacing="2px"
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      sx={props.sx}
    >
      <Button
        {...props}
        sx={{
          ...buttonStyles,
          ...(hovered ? ButtonHoveredStyle : {}),
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
        }}
      />
      <Button
        sx={{
          ...buttonStyles,
          ...(hovered ? ButtonHoveredStyle : {}),
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          padding: '0 12px',
        }}
      >
        <Icon as={BiChevronDown} height="20px" width="20px" />
      </Button>
    </HStack>
  )
}
