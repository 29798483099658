import {
  HOVER_BG_COLOUR,
  PRIMARY_TEXT_COLOUR,
  SIDEBAR_BUTTON_NAVIGATION_HEIGHT,
  SIDEBAR_EXTRA_NAVIGATION_DROPDOWN_WIDTH,
  SIDEBAR_PADDING_X,
  SIDEBAR_WIDTH,
} from '~constants/styles'

export const AVATAR_BUTTON_HEIGHT = '64px'

export const SidebarStyle = {
  height: 'inherit',
  backgroundColor: 'white',
  zIndex: 10,
  transitionProperty: 'width, min-width',
  transitionDuration: '150ms',
  transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
  position: 'relative',
  boxShadow:
    '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
}

export const SidebarContentStyle = {
  position: 'sticky',
  top: 0,
  bottom: 0,
  width: '100%',
  alignItems: 'flex-start',
}

export const ExtraSectionStyle = {
  position: 'relative',
}

export const ExtraSectionTitleStyle = {
  height: '42px',
  color: '#9AA0B3',
  fontWeight: 600,
  fontSize: '14px',
  padding: `0 ${SIDEBAR_PADDING_X}px`,
  display: 'flex',
  alignItems: 'center',
}

export const ExtraSectionNavigationStyle = {
  minWidth: SIDEBAR_WIDTH,
  width: SIDEBAR_WIDTH,
  alignItems: 'flex-start',
  position: 'absolute',
  left: 0,
  top: 0,
}

export const ExtraSectionNavigationDropdownStyle = {
  minWidth: SIDEBAR_EXTRA_NAVIGATION_DROPDOWN_WIDTH,
  backgroundColor: 'white',
  borderRadius: '4px',
  position: 'absolute',
  left: '100%',
  top: '0',
  marginLeft: '5px',
  boxShadow: '0px 0px 20px rgba(104, 104, 104, 0.3)',
  color: '#3C4764',
  paddingBottom: '8px',
}

export const HideCollapseStyle = {
  transitionProperty: 'all',
  transitionDuration: '200ms',
}

export const AvatarContainerStyle = {
  ...HideCollapseStyle,
  height: AVATAR_BUTTON_HEIGHT,
  alignItems: 'center',
}

// Sidebar Collapse Button Styles
export const SidebarCollapseButtonContainerStyle = {
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  borderTop: '1px solid #E9EAEE',
  height: SIDEBAR_BUTTON_NAVIGATION_HEIGHT,
  width: '100%',
}

export const SidebarCollapseButtonStyle = {
  padding: `0 ${SIDEBAR_PADDING_X}`,
  cursor: 'pointer',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  margin: 0,
  width: '72px',

  '&:hover': {
    backgroundColor: HOVER_BG_COLOUR,
  },
}

// Sidebar Navigation Button Styles
export const SidebarButtonStyle = {
  textAlign: 'left',
  justifyContent: 'left',
  color: PRIMARY_TEXT_COLOUR,
  fontSize: '14px',
  fontWeight: 600,
  borderRadius: 0,
  padding: 0,
  paddingRight: `${SIDEBAR_PADDING_X}px`,
  paddingLeft: `${SIDEBAR_PADDING_X}px`,
  width: '100%',

  transitionProperty: 'background-color',
  transitionDuration: '200ms',

  '&:hover': {
    backgroundColor: HOVER_BG_COLOUR,
  },
}

export const SidebarActiveButtonStyle = {
  ...SidebarButtonStyle,
  borderLeft: '4px solid #568DF4',
  backgroundColor: HOVER_BG_COLOUR,
  color: '#568DF4',
  paddingLeft: `${SIDEBAR_PADDING_X - 4}px`,
  '&:hover': {
    borderLeft: '4px solid #568DF4',
  },
}
