import { MouseEventHandler } from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'

import { SIDEBAR_BUTTON_NAVIGATION_HEIGHT } from '~/constants/styles'

import {
  SidebarActiveButtonStyle,
  SidebarButtonStyle,
} from '~components/Sidebar/styles'

interface SidebarButtonProps extends ButtonProps {
  active?: boolean
  onClick?: MouseEventHandler
  size?: 'sm' | 'md'
}

export const SidebarButton = ({
  active,
  onClick,
  children,
  size = 'md',
  sx = {},
  ...props
}: SidebarButtonProps): JSX.Element => {
  return (
    <Button
      variant="clear"
      onClick={onClick}
      sx={{
        ...(active ? SidebarActiveButtonStyle : SidebarButtonStyle),
        ...sx,
      }}
      height={size === 'md' ? SIDEBAR_BUTTON_NAVIGATION_HEIGHT : '52px'}
      {...props}
    >
      {children}
    </Button>
  )
}
