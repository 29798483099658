import type {
  SendLoginOtpRequestDto,
  SendLoginOtpResponseDto,
} from '~shared/types/auth.dto'

import { api } from '~lib/api'

export const sendLoginOtp = async (params: SendLoginOtpRequestDto) => {
  return api.url('/public/auth').post(params).json<SendLoginOtpResponseDto>()
}
