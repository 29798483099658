import { Outlet } from 'react-router-dom'
import { ThemeProvider } from '@opengovsg/design-system-react'

import { theme } from '~/theme'

export const DefaultThemeWrapper = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme} resetCSS>
      <Outlet />
    </ThemeProvider>
  )
}
