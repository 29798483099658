import { useEffect, useRef, useState } from 'react'
import { useToast } from '@chakra-ui/react'

const MIN_DESKTOP_WIDTH = 1070
export const useDesktopToast = (): void => {
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const isDesktop = windowSize.current[0] > MIN_DESKTOP_WIDTH
  const [hasToast, setHasToast] = useState(false)

  const toast = useToast()
  useEffect(() => {
    if (!isDesktop && !hasToast) {
      toast({
        title:
          'User experience is not optimised for your screen size. For a better experience, view on desktop.',
        status: 'success',
        isClosable: true,
      })
      setHasToast(true)
    }
  }, [hasToast, toast, isDesktop])
}
