import React, { useState } from 'react'
import { BiArrowFromLeft, BiLeftArrowAlt } from 'react-icons/bi'
import { Link as RouterLink } from 'react-router-dom'
import {
  matchPath,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  Box,
  Container,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react'
import { some } from 'lodash'

import { routes } from '~constants/routes'
import {
  SIDEBAR_ICON_SIZE,
  SIDEBAR_PADDING_X,
  SIDEBAR_WIDTH,
} from '~constants/styles'
import { AvatarMenu } from '~components/AvatarMenu'
import { ExtraNavigation } from '~components/Sidebar/ExtraNavigation'
import { SidebarButton } from '~components/Sidebar/SidebarButton'
import {
  AvatarContainerStyle,
  HideCollapseStyle,
  SidebarCollapseButtonContainerStyle,
  SidebarCollapseButtonStyle,
  SidebarContentStyle,
  SidebarStyle,
} from '~components/Sidebar/styles'
import {
  BackButtonProps,
  LogoRenderProps,
  MainNavigationRenderProps,
  SidebarCollapseButtonProps,
  SidebarProps,
} from '~components/Sidebar/types'
import { Tooltip } from '~components/Tooltip'

export const LOGO_COLLAPSED_WIDTH = '24px'
const LOGO_EXPANDED_WIDTH = '26px'

const ICON_TEXT_SPACING = '12px'

const SidebarCollapseButton = ({
  onClick,
  isCollapsed,
}: SidebarCollapseButtonProps) => {
  return (
    <Container sx={SidebarCollapseButtonStyle} onClick={onClick}>
      <Icon
        as={BiArrowFromLeft}
        width={SIDEBAR_ICON_SIZE}
        height={SIDEBAR_ICON_SIZE}
        color="#7B849C"
        sx={{
          transform: `rotate(${isCollapsed ? 0 : 180}deg)`,
          ...HideCollapseStyle,
        }}
      />
    </Container>
  )
}

const renderLogo = ({ isCollapsed, iconLogo, nameLogo }: LogoRenderProps) => {
  return (
    <RouterLink to="/events">
      <HStack spacing={ICON_TEXT_SPACING} p={`${SIDEBAR_PADDING_X}px`}>
        {React.createElement(iconLogo, {
          style: {
            minWidth: LOGO_COLLAPSED_WIDTH,
            height: LOGO_COLLAPSED_WIDTH,
            width: isCollapsed ? LOGO_COLLAPSED_WIDTH : LOGO_EXPANDED_WIDTH,
            transform: `scale(${isCollapsed ? 24 / 26 : 1})`,
            ...HideCollapseStyle,
          },
        })}
        {nameLogo &&
          React.createElement(nameLogo, {
            style: {
              height: '24px',
              minWidth: 'fit-content',
              width: 'fit-content',
              opacity: isCollapsed ? 0 : 1,
              visibility: isCollapsed ? 'hidden' : 'visible',
              ...HideCollapseStyle,
            },
          })}
      </HStack>
    </RouterLink>
  )
}

const doesPathMatch = (active: string, path: string) => {
  return !!matchPath(active, path)
}

export const isPathActive = (
  paths: string[] | string | undefined,
  active: string,
) => {
  return paths
    ? typeof paths === 'string'
      ? doesPathMatch(paths, active)
      : some(paths, (path) => doesPathMatch(path, active))
    : false
}

const replacePathWithValue = (
  pattern: string,
  values?: { [key: string]: any },
): string => {
  if (values) {
    let constructedPath = pattern
    Object.keys(values as { [key: string]: any }).forEach(
      (key: string) =>
        (constructedPath = constructedPath.replace(
          `:${key}`,
          values[key] as string,
        )),
    )

    return constructedPath
  }

  return pattern
}

export const sidebarButtonOnClick =
  (
    paths: string[] | string | undefined,
    active: string,
    navigateFn: NavigateFunction,
    params?: { [key: string]: any },
  ) =>
  () => {
    if (!paths) {
      navigateFn('*')
    }

    if (typeof paths === 'string') {
      navigateFn(replacePathWithValue(paths, params))
    } else if (Array.isArray(paths)) {
      navigateFn(replacePathWithValue(paths[0], params))
    }
  }

const renderMainNavigation = ({
  navigations,
  navigateFn,
  active,
  isCollapsed,
}: MainNavigationRenderProps) => {
  return (
    <VStack w="full" alignItems="left" spacing="0">
      {navigations.map((navigation, i) => {
        return (
          <SidebarButton
            active={isPathActive(navigation.path, active)}
            onClick={sidebarButtonOnClick(
              navigation.path,
              active,
              navigateFn,
              navigation.params as object,
            )}
            key={`navigation-${i}`}
          >
            <HStack spacing={ICON_TEXT_SPACING} sx={{ overflow: 'hidden' }}>
              <Tooltip
                label={navigation.name}
                placement="right"
                shouldWrapChildren
                hasArrow
                isDisabled={!isCollapsed}
              >
                <Icon
                  as={navigation.icon}
                  width={SIDEBAR_ICON_SIZE}
                  height={SIDEBAR_ICON_SIZE}
                />
              </Tooltip>
              <Text
                sx={HideCollapseStyle}
                opacity={isCollapsed ? 0 : 1}
                visibility={isCollapsed ? 'hidden' : 'visible'}
              >
                {navigation.name}
              </Text>
            </HStack>
          </SidebarButton>
        )
      })}
    </VStack>
  )
}

const Avatar = ({ isCollapsed }: { isCollapsed: boolean }) => {
  return (
    <Flex
      px={`${isCollapsed ? SIDEBAR_PADDING_X - 6 : SIDEBAR_PADDING_X}px`}
      sx={AvatarContainerStyle}
    >
      <AvatarMenu
        avatarButtonWrapperStyle={{
          justifyContent: 'flex-start',
          spacing: ICON_TEXT_SPACING,
          width: '100%',
        }}
        avatarNameWrapperStyle={{
          opacity: isCollapsed ? 0 : 1,
          sx: HideCollapseStyle,
          marginLeft: ICON_TEXT_SPACING,
          alignItems: 'center',
          flex: 1,
        }}
      />
    </Flex>
  )
}

const renderBackButton = ({ isCollapsed, navigateFn }: BackButtonProps) => {
  return (
    <SidebarButton onClick={() => navigateFn(routes.events)}>
      <HStack spacing={ICON_TEXT_SPACING} sx={{ overflow: 'hidden' }}>
        <Icon
          as={BiLeftArrowAlt}
          width={SIDEBAR_ICON_SIZE}
          height={SIDEBAR_ICON_SIZE}
        />
        <Text
          sx={HideCollapseStyle}
          opacity={isCollapsed ? 0 : 1}
          visibility={isCollapsed ? 'hidden' : 'visible'}
        >
          Back
        </Text>
      </HStack>
    </SidebarButton>
  )
}

export const Sidebar = ({
  nameLogo,
  iconLogo,
  navigations,
  extraSection,
  hideAvatar = false,
  hasBackButton = false,
  renderDetails,
  renderInfoTopComponent,
  renderInfoBottomComponent,
}: SidebarProps): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const active = location.pathname

  return (
    <Flex
      flexDir="column"
      minWidth={isCollapsed ? '72px' : `${SIDEBAR_WIDTH}px`}
      width={isCollapsed ? '72px' : `${SIDEBAR_WIDTH}px`}
      sx={SidebarStyle}
    >
      <VStack
        spacing="0"
        sx={{
          ...SidebarContentStyle,
          maxWidth: isCollapsed ? '72px' : `${SIDEBAR_WIDTH}px`,
        }}
      >
        {hasBackButton &&
          renderBackButton({
            isCollapsed,
            navigateFn: navigate,
          })}

        {iconLogo &&
          renderLogo({
            isCollapsed,
            iconLogo,
            nameLogo,
          })}

        {renderDetails && renderDetails({ isCollapsed })}

        {renderMainNavigation({
          isCollapsed,
          navigations,
          active,
          navigateFn: navigate,
        })}

        {extraSection && (
          <ExtraNavigation
            title={extraSection.title}
            navigations={extraSection.navigations}
            active={active}
            isCollapsed={isCollapsed}
            navigateFn={navigate}
          />
        )}

        {renderInfoTopComponent && renderInfoTopComponent({ isCollapsed })}
      </VStack>
      <Box w="inherit" position="fixed" bottom={0}>
        {renderInfoBottomComponent &&
          renderInfoBottomComponent({ isCollapsed })}

        {!hideAvatar && <Avatar isCollapsed={isCollapsed} />}

        <Flex sx={SidebarCollapseButtonContainerStyle}>
          <SidebarCollapseButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            isCollapsed={isCollapsed}
          />
        </Flex>
      </Box>
    </Flex>
  )
}
