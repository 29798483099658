import React from 'react'
import ReactDOM from 'react-dom/client'

// import { initDatadog } from '~lib/monitoring'
import { App } from './app'
import * as serviceWorker from './serviceWorker'

// Temporarily disabling RUM to save on cost
// TODO: re-enable RUM when we want it back
// initDatadog()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
