export const LabelStyle = {
  color: '#293044',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: `'Inter', sans-serif`,
}

export const InputStyle = {
  color: '#293044',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: `'Inter', sans-serif`,
  border: '1px solid #C9CCCF',

  '&:focus-visible, &[data-focus-visible]': {
    border: '1px solid #5389EF',
  },

  '&[aria-invalid=true], &[data-invalid]': {
    border: '1px solid #C05050',
  },

  '&:placeholder': {
    color: '#ABADB2',
  },
}
