export const isEventOngoing = (
  startTimestamp: Date | string,
  endTimestamp: Date | string,
) => {
  const now = new Date()
  return now >= new Date(startTimestamp) && now < new Date(endTimestamp)
}

export const isEventUpcoming = (startTimestamp: Date | string) => {
  const now = new Date()
  return new Date(startTimestamp) > now
}

export const isEventCompleted = (endTimestamp: Date | string) => {
  const now = new Date()
  return new Date(endTimestamp) < now
}
