import { useQuery } from '@tanstack/react-query'

import { api } from '~/lib/api'

export const useGrowthbookApiClientKey = () => {
  const { data, isLoading } = useQuery<string | undefined>(
    ['growthbook-api-client-key'],
    () => api.get('/public/config/growthbookApiClientKey').json<string>(),
    { retry: false },
  )
  return {
    growthbookApiClientKey: data,
    isLoadingGrowthbookApiClientKey: isLoading,
  }
}
