import React, { useEffect, useState } from 'react'
import { BiBarChartAlt2, BiGroup, BiInfoCircle } from 'react-icons/bi'
import { Outlet, useParams } from 'react-router-dom'
import { Container, Flex, HStack, Text, VStack } from '@chakra-ui/react'

import { isEventCompleted, isEventOngoing } from '~shared/utils/event'

import { ContentContainerStyle } from '~/app/styles'

import { routes } from '~constants/routes'
import { SIDEBAR_PADDING_X, SIDEBAR_WIDTH } from '~constants/styles'
import { useGetEvent, useGetEventAttendances } from '~hooks/queries'
import { useDesktopToast } from '~hooks/useDesktopToast'
import { parseEventAndAttendancesData } from '~utils/dataParser'
import { EventStatusTag } from '~components/EventStatusTag'
import { InfoBox } from '~components/InfoBox'
import { LOGO_COLLAPSED_WIDTH, Sidebar } from '~components/Sidebar'
import { HideCollapseStyle } from '~components/Sidebar/styles'
import { Navigation, SidebarRenderProps } from '~components/Sidebar/types'

import { Event } from '../features/events/mockData'
import EngageSGLogo from '../images/engagesg-logo.svg'

interface EventSidebarDetailsProps extends SidebarRenderProps {
  title?: string
  startTimestamp?: Date | string
  endTimestamp?: Date | string
}

const EventSidebarDetailsTitleStyle = {
  fontSize: '28px',
  fontWeight: 700,
  lineHeight: '40px',
  color: '#272D41',
}

const EventLogoStyle = {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
}

const EventSidebarDetails = ({
  isCollapsed,
  title,
  startTimestamp,
  endTimestamp,
}: EventSidebarDetailsProps) => {
  return (
    <VStack
      spacing={isCollapsed ? '0' : '24px'}
      sx={{
        alignItems: 'flex-start',
        overflow: 'hidden',
        padding: isCollapsed ? 0 : `${SIDEBAR_PADDING_X}px 0`,
        ...HideCollapseStyle,
      }}
    >
      <Container
        sx={{
          margin: isCollapsed
            ? `${SIDEBAR_PADDING_X}px`
            : `0 ${SIDEBAR_PADDING_X}px`,
          padding: 0,
          width: isCollapsed ? LOGO_COLLAPSED_WIDTH : '72px',
          height: isCollapsed ? LOGO_COLLAPSED_WIDTH : '72px',
          backgroundImage: `url(${EngageSGLogo})`,
          ...EventLogoStyle,
        }}
      />
      <VStack
        spacing="24px"
        alignItems="left"
        sx={{
          maxHeight: isCollapsed ? 0 : 'fit-content',
          padding: `0 ${SIDEBAR_PADDING_X}px`,
          width: `${SIDEBAR_WIDTH}px`,
          ...HideCollapseStyle,
        }}
      >
        {title && (
          <Text
            sx={{
              ...EventSidebarDetailsTitleStyle,
            }}
            title={title}
          >
            {`${title.slice(0, 50)}${title.length > 50 ? '...' : ''}`}
          </Text>
        )}
        {startTimestamp && endTimestamp && (
          <EventStatusTag
            startTimestamp={startTimestamp}
            endTimestamp={endTimestamp}
          />
        )}
      </VStack>
    </VStack>
  )
}

const renderLoadingInsightInfo = ({ isCollapsed }: SidebarRenderProps) => {
  return (
    <Container
      sx={{
        padding: isCollapsed
          ? `0 ${SIDEBAR_PADDING_X}px`
          : `${SIDEBAR_PADDING_X}px`,
        maxHeight: isCollapsed ? 0 : 'fit-content',
        overflow: 'hidden',
        ...HideCollapseStyle,
      }}
    >
      <InfoBox
        textStyle={{ fontSize: '14px', lineHeight: '20px ' }}
        iconComponent={
          <Text sx={{ fontSize: '18px', lineHeight: '24px' }}>⏳</Text>
        }
      >
        You will be able to view the Insights page when the event has started.
      </InfoBox>
    </Container>
  )
}

export const EventContext = React.createContext<Event | undefined>(undefined)

export const AppWithEventSidebar = (): JSX.Element => {
  // const { user } = useAuth()
  const { id } = useParams()
  const [event, setEvent] = useState<Event | undefined>(undefined)
  useDesktopToast()

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 2)
  }, [])

  const { data: attendances } = useGetEventAttendances(id as string)
  const { data: eventData } = useGetEvent(id as string)

  useEffect(() => {
    if (attendances && eventData) {
      setEvent(parseEventAndAttendancesData(attendances, eventData))
    }
  }, [attendances, eventData])

  const isOngoing =
    eventData &&
    isEventOngoing(eventData.startTimestamp, eventData.endTimestamp)
  const isCompleted = eventData && isEventCompleted(eventData.endTimestamp)

  const navigations: Navigation[] = [
    {
      path: [routes.eventAbout, routes.event],
      icon: BiInfoCircle,
      name: 'About',
      params: { id },
    },
    {
      path: routes.eventAttendees,
      icon: BiGroup,
      name: 'Attendees',
      params: { id },
    },
  ]

  if (isOngoing || isCompleted) {
    navigations.push({
      path: routes.eventInsights,
      icon: BiBarChartAlt2,
      name: 'Insights',
      params: { id },
    })
  }

  return (
    <HStack
      flex={1}
      alignItems="left"
      spacing="0px"
      sx={{
        position: 'relative',
        maxWidth: '100vw',
        overflowX: 'clip',
        borderTop: 'solid',
        borderTopWidth: '1px',
        borderColor: '#BABECB',
      }}
    >
      <Sidebar
        navigations={navigations}
        renderDetails={({ isCollapsed }: SidebarRenderProps) => (
          <EventSidebarDetails
            isCollapsed={isCollapsed}
            startTimestamp={eventData?.startTimestamp}
            endTimestamp={eventData?.endTimestamp}
            title={eventData?.title}
          />
        )}
        renderInfoTopComponent={
          isOngoing || isCompleted ? undefined : renderLoadingInsightInfo
        }
        hideAvatar
        hasBackButton
      />
      <Flex sx={ContentContainerStyle}>
        <EventContext.Provider value={event}>
          <Outlet />
        </EventContext.Provider>
      </Flex>
    </HStack>
  )
}
