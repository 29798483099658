import { ReactNode } from 'react'
import { IconType } from 'react-icons'
import { AiFillInfoCircle } from 'react-icons/ai'
import { HStack, Icon, Text, TextProps } from '@chakra-ui/react'

const InfoBoxStyle = {
  backgroundColor: '#F6F7FC',
  borderRadius: '4px',
  padding: '16px',
  color: '#293044',
  fontFamily: `'Inter', sans-serif`,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  alignItems: 'flex-start',
}

const InfoBoxSecondaryStyle = {
  ...InfoBoxStyle,
  border: '1px solid #9DBDFB',
  backgroundColor: 'white',
}

interface InfoBoxProps extends TextProps {
  type?: 'p' | 's'
  icon?: IconType
  iconComponent?: ReactNode
  textStyle?: object
}

export const InfoBox = ({
  children,
  type = 'p',
  icon,
  iconComponent,
  textStyle = {},
}: InfoBoxProps) => {
  return (
    <HStack
      sx={type === 'p' ? InfoBoxStyle : InfoBoxSecondaryStyle}
      spacing="10px"
    >
      {iconComponent || (
        <Icon
          as={icon || AiFillInfoCircle}
          height="20px"
          width="20px"
          color="#5389EF"
        />
      )}
      <Text sx={textStyle}>{children}</Text>
    </HStack>
  )
}
