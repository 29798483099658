import '@fontsource/inter'
import '@fontsource/inter/500.css'
import '@fontsource/inter/700.css'
import '@fontsource/plus-jakarta-sans'
import '@fontsource/plus-jakarta-sans/600.css'
import '@fontsource/plus-jakarta-sans/700.css'

import { useRef } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import {
  RestrictedGovtMasthead,
  ThemeProvider,
} from '@opengovsg/design-system-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { engageTheme } from '~/theme/engageTheme'

import { AuthProvider } from '~lib/auth'

import { GrowthbookWrapper } from '~features/feature-flag/GrowthbookWrapper'

import { AppRouter } from './AppRouter'

export const queryClient = new QueryClient()

export const App = (): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider
        theme={engageTheme}
        resetCSS
        portalZIndex={40}
        toastOptions={{
          defaultOptions: { position: 'top' },
          portalProps: { containerRef: ref },
        }}
      >
        <AuthProvider>
          <GrowthbookWrapper>
            <Flex minHeight="100vh" flexDir="column">
              <RestrictedGovtMasthead />
              <AppRouter />
              <Box ref={ref} />
            </Flex>
          </GrowthbookWrapper>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
