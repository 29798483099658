import { useEffect, useMemo } from 'react'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'

import { GrowthbookFeaturesMap } from '~shared/types/feature-flag'
import { getMockGrowthbookClient } from '~shared/utils/growthbook'

import { useAuth } from '~lib/auth'
import { useGrowthbookApiClientKey } from '~hooks/useGrowthbookApiClientKey'

export const GrowthbookWrapper = ({
  children,
}: React.PropsWithChildren): JSX.Element => {
  const { growthbookApiClientKey } = useGrowthbookApiClientKey()
  const { user } = useAuth()

  const growthbook = useMemo(() => {
    if (process.env.NODE_ENV === 'development' || !growthbookApiClientKey)
      return getMockGrowthbookClient({
        isDevEnv: process.env.NODE_ENV === 'development',
      }) as GrowthBook
    return new GrowthBook<GrowthbookFeaturesMap>({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: growthbookApiClientKey,
    })
  }, [growthbookApiClientKey])

  useEffect(() => {
    if (!growthbook) return // do nothing
    growthbook.setAttributes({
      email: user?.email,
      id: user?.id,
      loggedIn: !!user?.id,
    })
    void growthbook.loadFeatures()
  }, [growthbook, user])

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  )
}
