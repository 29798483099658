import { useNavigate } from 'react-router-dom'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'

import { useAuth } from '~lib/auth'
import {
  DROPDOWN_ITEM_HEIGHT,
  SIDEBAR_EXTRA_NAVIGATION_DROPDOWN_WIDTH,
} from '~constants/styles'

interface AvatarMenuProps {
  avatarButtonWrapperStyle?: object
  avatarNameWrapperStyle?: object
}

const AvatarMenuButtonStyle = {
  margin: 0,
  padding: 0,
  width: '100%',
  color: '#5D6785',

  '&:hover, &:focus, &:active, &[data-active]': {
    background: 'transparent',
  },
}

const AvatarMenuListStyle = {
  boxShadow: '0px 0px 20px rgba(104, 104, 104, 0.3)',
  borderRadius: '4px',
  padding: '4px 0',
  minWidth: SIDEBAR_EXTRA_NAVIGATION_DROPDOWN_WIDTH,
}

const AvatarNameStyle = {
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: `'Inter', sans-serif`,
  lineHeight: '20px',
  color: '#3C4764',
}

const AvatarMenuListItemStyle = {
  ...AvatarNameStyle,
  color: '#2C2E34',
  padding: '0 16px',
}

export const AvatarMenu = ({
  avatarButtonWrapperStyle = {},
  avatarNameWrapperStyle = {},
}: AvatarMenuProps): JSX.Element => {
  const { user, logout } = useAuth()
  const navigate = useNavigate()
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            variant="clear"
            isActive={isOpen}
            sx={AvatarMenuButtonStyle}
            bg="none"
          >
            <Flex {...avatarButtonWrapperStyle}>
              <Avatar
                name={user?.email || 'a'}
                size="sm"
                bg="#E7EFFC"
                color="#1361F0"
              />
              <Flex {...avatarNameWrapperStyle}>
                <Text sx={AvatarNameStyle} flex={1} textAlign="left">
                  {user?.email || 'a'}
                </Text>
                <ChevronDownIcon
                  color="#7B849C"
                  width="20px"
                  height="20px"
                  sx={{
                    transform: `rotate(${isOpen ? 180 : 0}deg)`,
                    transitionProperty: 'transform',
                    transitionDuration: '300ms',
                    transitionTimingFunction: 'ease-out',
                  }}
                />
              </Flex>
            </Flex>
          </MenuButton>
          <MenuList sx={AvatarMenuListStyle}>
            <MenuItem
              onClick={async () => {
                await logout()
                navigate('/')
              }}
              height={DROPDOWN_ITEM_HEIGHT}
              sx={AvatarMenuListItemStyle}
            >
              Logout
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
