import { Outlet } from 'react-router-dom'
import reactStringReplace from 'react-string-replace'
import { Link, Text } from '@chakra-ui/react'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { Banner } from '@opengovsg/design-system-react'

import { GrowthbookFeatures } from '~shared/types/feature-flag'

export const AppWithAdminBanner = (): JSX.Element => {
  const adminBanner = useFeatureValue(GrowthbookFeatures.adminBanner, '')
  const adminBannerWithLinks = reactStringReplace(
    adminBanner,
    // captures [...](...) as a single group
    /(\[[^\]]+\]\([^\s]+\))/g,
    (match, i) => {
      // split it into two groups, the text and the URL
      const groups = /\[([^\]]+)\]\(([^\s]+)\)/g.exec(match)
      const text = groups![1]
      const url = groups![2]
      return (
        <Text whiteSpace="pre">
          {' '}
          <Link
            key={i}
            href={url}
            color="white"
            _hover={{ color: 'slate' }}
            isExternal
          >
            {text}
          </Link>
        </Text>
      )
    },
  )
  return (
    <>
      {adminBanner && <Banner>{adminBannerWithLinks}</Banner>}
      <Outlet />
    </>
  )
}
