import moment from 'moment'

const RELATIVE_TIMESTAMP_LIMIT = 60 * 60 * (7 * 24) * 1000 // s * m * h * 1000

export const parseTimestamp = (timestamp: Date | string): string => {
  return moment(timestamp).format('Do MMM YYYY, h:mm a')
}

export const parseRelativeTimestamp = (timestamp: number): string => {
  return Date.now() - timestamp >= RELATIVE_TIMESTAMP_LIMIT
    ? parseTimestamp(new Date(timestamp))
    : moment(timestamp).fromNow()
}

export const parseTimestampForActivity = (timestamp: Date | string): string => {
  return moment(timestamp).format('DD/MM/YYYY, h:mm:ss a')
}

export const parseTimestampAsDate = (
  timestamp: Date | string,
  withSpaces = true,
): string => {
  return withSpaces
    ? moment(timestamp).format('DD MMM YYYY')
    : moment(timestamp).format('DDMMMYYYY')
}

export const parseTimestampForEvent = (
  start: Date | string,
  end: Date | string,
): string => {
  const startDate = moment(start)
  const endDate = moment(end)

  const startDateString = startDate.format('DD MMMM YYYY')
  const startTimeString = startDate.format('hh:mm A')
  const endTimeString = endDate.format('hh:mm A')

  if (startDate.isSame(endDate, 'day')) {
    return `${startDateString}, ${startTimeString} to ${endTimeString}`
  }
  const endDateString = endDate.format('DD MMMM YYYY')
  return `${startDateString}, ${startTimeString} - ${endDateString}, ${endTimeString}`
}

const calculateAgeAtDate = (targetDate: Date, dateOfBirth: Date): number => {
  let age = targetDate.getFullYear() - dateOfBirth.getFullYear()
  const m = targetDate.getMonth() - dateOfBirth.getMonth()
  if (m < 0 || (m === 0 && targetDate.getDate() < dateOfBirth.getDate())) {
    age--
  }
  return age
}

export const getAgeFromEvent = (
  dobString: string,
  eventStartTimestamp: Date | string,
): number => {
  const eventStartTime = new Date(eventStartTimestamp)
  const dob = new Date(dobString)
  return calculateAgeAtDate(eventStartTime, dob)
}

export const getAge = (dobString: string): number => {
  const today = new Date()
  const dob = new Date(dobString)
  return calculateAgeAtDate(today, dob)
}
