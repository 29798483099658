import { GrowthbookFeatureValue } from '../types/feature-flag'

export const getMockGrowthbookClient = ({
  isDevEnv,
}: {
  isDevEnv: boolean
}) => ({
  loadFeatures: () => Promise.resolve(),
  isOn: (
    featureKey: string,
    fallbackValue = isDevEnv, // defaults to true in dev mode and false otherwise
  ): boolean => {
    console.log(
      `Feature ${featureKey} using fallbackValue ${fallbackValue.toString()} by default as Growthbook is not initialized`,
    )
    return fallbackValue
  },
  getFeatureValue: (
    featureKey: string,
    fallbackValue: GrowthbookFeatureValue,
  ) => {
    console.log(
      `Feature ${featureKey} using fallbackValue ${
        fallbackValue?.toString() || ''
      } by default as Growthbook is not initialized`,
    )
    return fallbackValue
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setAttributes: (_attributes: unknown) =>
    console.log(`Growthbook is not initialized, not setting attributes`),
})
