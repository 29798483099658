import React from 'react'
import { Container, Divider, Text, VStack } from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'

import { CONTACT_US } from '~shared/constants/links'
import { APP_NAME } from '~shared/index'

import { HideCollapseStyle } from '~components/Sidebar/styles'
import { SidebarRenderProps } from '~components/Sidebar/types'

import {
  FeedbackInfoContainerStyle,
  FeedbackInfoCopyStyle,
  FeedbackInfoStyle,
  FeedbackInfoTitleStyle,
} from './styles'

export const FeedbackSidebarInfo = ({ isCollapsed }: SidebarRenderProps) => {
  return (
    <Container sx={FeedbackInfoStyle}>
      <VStack
        sx={{
          opacity: isCollapsed ? 0 : 1,
          visibility: isCollapsed ? 'hidden' : 'visible',
          ...FeedbackInfoContainerStyle,
          ...HideCollapseStyle,
        }}
        spacing="12px"
      >
        <Text sx={FeedbackInfoTitleStyle} fontSize="20px">
          👋
        </Text>
        <Text sx={FeedbackInfoTitleStyle}>Hi, how are your events?</Text>
        <Text sx={FeedbackInfoCopyStyle}>
          {APP_NAME} helps you take attendance and generate insights
          effortlessly.
        </Text>
        <Divider />
        <Text sx={FeedbackInfoCopyStyle}>
          Have feedback or feature requests?{' '}
          <Link href={CONTACT_US} color="#4B84EF" target="_blank">
            Share with us
          </Link>
        </Text>
      </VStack>
    </Container>
  )
}
