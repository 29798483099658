// Sidebar related styles
export const SIDEBAR_WIDTH = 280
export const SIDEBAR_PADDING_X = 24
export const SIDEBAR_BUTTON_NAVIGATION_HEIGHT = '56px'
export const SIDEBAR_EXTRA_NAVIGATION_DROPDOWN_WIDTH = 250
export const SIDEBAR_ICON_SIZE = '20px'
export const DROPDOWN_ITEM_HEIGHT = '48px'

// Colour constants
export const BG_COLOUR = '#F8F9FA'
export const PRIMARY_BLUE_COLOUR = '#4B84EF'
export const HOVER_BG_COLOUR = '#F7F9FE'
export const GREY_HOVER_BG_COLOUR = '#E9EAEE'
export const PRIMARY_TEXT_COLOUR = '#3C4764'
