import { random, startCase } from 'lodash'

import { AttendanceWithoutEventDto } from '~shared/types/attendance.dto'
import { EventDto, GetEventResponseDto } from '~shared/types/event.dto'
import { ListParticipantsResponseDto } from '~shared/types/participant.dto'

import { Event } from '~features/events/mockData'
import { Gender, Race } from '~features/participants/mockData'

import { getAge, getAgeFromEvent } from './datetime'

// TODO: modify to return Event from ~shared instead of Event from mockData
export const parseEventData = (e: EventDto): Event => {
  const tags = []

  if (e.type) {
    tags.push({ text: e.type })
  }

  return {
    id: e.id,
    name: e.title,
    description: e.description,
    mode: e.mode,
    type: e.type,
    endTime: new Date(e.endTimestamp).getTime(),
    startTime: new Date(e.startTimestamp).getTime(),
    location: e.location || '-',
    attendance: 0,
    rsvp: 0,
    tags: [
      {
        text: 'Technology',
      },
      {
        text: 'Hackathon',
      },
      ...tags,
    ],
    createdBy: {
      email: e.createdBy?.email,
    },
    rating: random(1, 5),
    agencies: e.organisers?.length
      ? e.organisers.map((organiser) => organiser.name)
      : [],
  }
}

export const parseParticipantData = (data: ListParticipantsResponseDto) => {
  const participants = data.map((participant) => ({
    name: startCase(participant.name.toLowerCase()),
    nric: participant.uin,
    email: participant.email?.toLowerCase(),
    // TODO: age should be relative to event start time
    age: participant.dob ? getAge(participant.dob) : undefined,
    gender:
      Gender[
        startCase(participant.gender?.toLowerCase()) as keyof typeof Gender
      ] || startCase(participant.gender?.toLowerCase()),
    race:
      Race[startCase(participant.race?.toLowerCase()) as keyof typeof Race] ||
      startCase(participant.race?.toLowerCase()),
    mobileNumber: participant.contactNumber || undefined,
    consentGiven: participant?.allowContact,
  }))
  return participants
}

export const parseEventAndAttendancesData = (
  attendances: AttendanceWithoutEventDto[],
  event: GetEventResponseDto,
): Event => {
  const parsedEvent = parseEventData(event)

  parsedEvent.attendees = attendances.map((attendee) => ({
    name: startCase(attendee.participant.name.toLowerCase()),
    nric: attendee.participant.uin,
    email: attendee.participant.email?.toLowerCase(),
    age: attendee.participant.dob
      ? getAgeFromEvent(
          attendee.participant.dob,
          new Date(parsedEvent.startTime),
        )
      : undefined,
    gender:
      Gender[
        startCase(
          attendee.participant.gender?.toLowerCase(),
        ) as keyof typeof Gender
      ] || startCase(attendee.participant.gender?.toLowerCase()),
    race:
      Race[
        startCase(attendee.participant.race?.toLowerCase()) as keyof typeof Race
      ] || startCase(attendee.participant.race?.toLowerCase()),
    mobileNumber: attendee.participant.contactNumber || undefined,
    activities: [
      {
        description: 'Entered on',
        timestamp: new Date(attendee.updatedAt),
      },
    ],
    consentGiven: attendee?.participant.allowContact,
  }))

  return parsedEvent
}
