const eventRoutes = {
  event: '/events/:id',
  myEvents: '/events/my',
  allEvents: '/events/all',
  newEvent: '/events/new',
  eventsParticipants: '/events/participants',
  eventInsights: '/events/:id/insights',
  eventAttendees: '/events/:id/attendees',
  eventAttendance: '/events/:id/attendance',
  eventAttendanceQr: '/events/:id/qr',
  eventEdit: '/events/:id/edit',
  eventSettings: '/events/:id/settings',
  eventAbout: '/events/:id/about',
  eventRsvp: '/events/:id/rsvp',
}

export const routes = {
  index: '/',
  login: '/login',
  events: '/events',
  // participants: '/participants',
  health: '/health',
  callback: '/callback',
  resident: {
    _base: '/checkin/:eventId',
  },
  ...eventRoutes,
}
