import { Flex, Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react'

const TooltipStyle = {
  display: 'flex',
  borderRadius: '4px',
  padding: '4px 8px',
  fontSize: '12px',
  fontFamily: `'Inter', sans-serif`,
}

export const Tooltip = ({ children, ...props }: TooltipProps): JSX.Element => {
  return (
    <ChakraTooltip {...props} sx={TooltipStyle}>
      <Flex>{children}</Flex>
    </ChakraTooltip>
  )
}
