import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  AttendanceWithoutEventDto,
  GetAttendancesAndRsvpsResponseDto,
} from '~shared/types/attendance.dto'
import { GetEventResponseDto } from '~shared/types/event.dto'
import { ListEventsResponseDto } from '~shared/types/event.dto'
import { ListOrganisationResponseDto } from '~shared/types/organisation.dto'
import { ListParticipantsResponseDto } from '~shared/types/participant.dto'
import { TopicDto } from '~shared/types/topic.dto'

import { api } from '~lib/api'

export const useGetEvent = (
  eventId: string,
): UseQueryResult<GetEventResponseDto> => {
  return useQuery(
    ['Events', eventId],
    () => api.url('/events').get(`/${eventId}`).json<GetEventResponseDto>(),
    {
      staleTime: Infinity,
    },
  )
}

export const useGetAllEvents = (): UseQueryResult<ListEventsResponseDto> => {
  return useQuery(
    ['Events', { type: 'all' }],
    () => api.url(`/events`).get().json<ListEventsResponseDto>(),
    {
      staleTime: Infinity,
    },
  )
}

export const useGetMyEvents = (): UseQueryResult<ListEventsResponseDto> => {
  return useQuery(['Events', { type: 'my' }], () =>
    api
      .url(`/events`)
      .query({ filterByUser: true })
      .get()
      .json<ListEventsResponseDto>(),
  )
}

export const useGetEventAttendances = (
  eventId: string,
): UseQueryResult<AttendanceWithoutEventDto[]> => {
  return useQuery(['attendances', eventId], () =>
    api
      .url(`/events/${eventId}/attendances`)
      .get()
      .json<AttendanceWithoutEventDto>(),
  )
}

export const useGetEventAttendancesAndRsvps = (
  eventId: string,
): UseQueryResult<GetAttendancesAndRsvpsResponseDto> => {
  return useQuery(['attendancesAndRsvps', eventId], () =>
    api
      .url(`/events/${eventId}/attendancesAndRsvps`)
      .get()
      .json<GetAttendancesAndRsvpsResponseDto>(),
  )
}

export const useGetTopics = (): UseQueryResult<TopicDto[]> => {
  return useQuery(['topics'], () => api.url('/topics').get().json<TopicDto[]>())
}

export const useGetParticipants =
  (): UseQueryResult<ListParticipantsResponseDto> => {
    return useQuery(['allParticipants'], () =>
      api.url(`/participants`).get().json<ListParticipantsResponseDto>(),
    )
  }

/**
 * Fetches a list of organisation options.
 *
 * @returns list of organisation options
 */
export const useGetOrganisationOptions =
  (): UseQueryResult<ListOrganisationResponseDto> => {
    return useQuery(
      ['organisations'],
      () => api.url('/organisations').get().json<ListOrganisationResponseDto>(),
      {
        staleTime: Infinity,
      },
    )
  }
