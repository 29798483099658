import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormControl, HStack, Text, VStack } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Input } from '@opengovsg/design-system-react'
import { z } from 'zod'

import { isGovSgOrWhitelistedEmail } from '~shared/decorators/is-gov-sg-or-whitelisted-email'

import { PrimaryButton } from '~components/Buttons'
import { ErrorMessage } from '~components/Forms/ErrorMessage'

import { InputStyle, LabelStyle } from '../styles'

const schema = z.object({
  email: z
    .string()
    .trim()
    .min(1, 'Please enter an email address.')
    .email({ message: 'Please enter a valid email address.' })
    .refine(isGovSgOrWhitelistedEmail, {
      message:
        'Please sign in with a gov.sg or valid whitelisted email address.',
    }),
})

export type LoginFormInputs = {
  email: string
}
interface LoginFormProps {
  onSubmit: (inputs: LoginFormInputs) => Promise<void>
}

export const LoginForm = ({ onSubmit }: LoginFormProps): JSX.Element => {
  const [filled, setFilled] = useState(false)

  const onSubmitForm = async (inputs: LoginFormInputs) => {
    inputs.email = inputs.email.toLowerCase()
    return onSubmit(inputs).catch((e) => {
      setError('email', { type: 'server', message: e.json.message })
    })
  }

  const { handleSubmit, register, formState, setError } =
    useForm<LoginFormInputs>({
      resolver: zodResolver(schema),
    })

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitForm)}>
      <FormControl
        isRequired
        isInvalid={!!formState.errors.email}
        isReadOnly={formState.isSubmitting}
        mb="2.5rem"
      >
        <VStack spacing="12px" alignItems="left">
          <Text sx={LabelStyle}>
            Log in with a .gov.sg or other whitelisted email address
          </Text>
          <Input
            autoComplete="email"
            autoFocus
            placeholder="e.g. user@agency.gov.sg"
            sx={InputStyle}
            {...register('email')}
            onChange={(e) => {
              setFilled(e.target.value.length > 0)
              return register('email').onChange(e)
            }}
          />
          <ErrorMessage>{formState.errors.email?.message}</ErrorMessage>
        </VStack>
      </FormControl>
      <HStack justifyContent="left">
        <PrimaryButton
          isDisabled={!filled || !!formState.errors.email?.message}
          isLoading={formState.isSubmitting}
          type="submit"
        >
          Login
        </PrimaryButton>
      </HStack>
    </form>
  )
}
