import { PropsWithChildren, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

import { AppWithEventSidebar } from '~/app/AppWithEventSidebar'

import { routes } from '~constants/routes'
import { lazyImport } from '~utils/lazyImport'

import { DefaultThemeWrapper } from '~features/theme'

import { AppWithAdminBanner } from './AppWithAdminBanner'
import { AppWithSidebar } from './AppWithSidebar'

const { AuthRoutes } = lazyImport(() => import('~features/auth'), 'AuthRoutes')
const { MyEventsRoutes } = lazyImport(
  () => import('~features/events'),
  'MyEventsRoutes',
)
const { NewEventRoutes } = lazyImport(
  () => import('~features/events'),
  'NewEventRoutes',
)
const { EditEventRoutes } = lazyImport(
  () => import('~features/events'),
  'EditEventRoutes',
)
const { AllEventsRoutes } = lazyImport(
  () => import('~features/events'),
  'AllEventsRoutes',
)
const { RsvpRoutes } = lazyImport(
  () => import('~features/events'),
  'RsvpRoutes',
)
const { ParticipantsRoutes } = lazyImport(
  () => import('~features/participants'),
  'ParticipantsRoutes',
)
const { AttendeesRoutes } = lazyImport(
  () => import('~features/attendees'),
  'AttendeesRoutes',
)
const { AttendanceQrCodeRoutes } = lazyImport(
  () => import('~features/attendees'),
  'AttendanceQrCodeRoutes',
)
const { AttendanceRedirectRoutes } = lazyImport(
  () => import('~features/attendees'),
  'AttendanceRedirectRoutes',
)
const { InsightRoutes } = lazyImport(
  () => import('~features/insights'),
  'InsightRoutes',
)
const { AboutRoutes } = lazyImport(
  () => import('~features/about'),
  'AboutRoutes',
)
const { CallbackRoutes } = lazyImport(
  () => import('~features/callback'),
  'CallbackRoutes',
)
const { ResidentRoutes } = lazyImport(
  () => import('~features/resident'),
  'ResidentRoutes',
)

const { LandingPageRoutes } = lazyImport(
  () => import('~features/landing'),
  'LandingPageRoutes',
)

const WithSuspense = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<Box bg="neutral.100" minH="$100vh" w="100vw" />}>
    {children}
  </Suspense>
)

export const AppRouter = (): JSX.Element => {
  return (
    <WithSuspense>
      <BrowserRouter>
        <Routes>
          <Route path={routes.index} element={<LandingPageRoutes />} />
          <Route path={routes.login} element={<AuthRoutes />} />

          <Route element={<AppWithAdminBanner />}>
            <Route path={routes.newEvent} element={<NewEventRoutes />} />
            <Route path={routes.eventEdit} element={<EditEventRoutes />} />
            <Route path={routes.eventRsvp} element={<RsvpRoutes />} />
          </Route>

          <Route path={routes.callback} element={<DefaultThemeWrapper />}>
            <Route index element={<CallbackRoutes />} />
          </Route>

          <Route path={routes.resident._base}>
            <Route index element={<ResidentRoutes />} />
          </Route>

          <Route path={routes.events} element={<AppWithAdminBanner />}>
            <Route element={<AppWithSidebar />}>
              <Route index element={<MyEventsRoutes />} />
              <Route path={routes.allEvents} element={<AllEventsRoutes />} />
              <Route
                path={routes.eventsParticipants}
                element={<ParticipantsRoutes />}
              />
              <Route path={routes.myEvents} element={<MyEventsRoutes />} />
            </Route>
          </Route>

          <Route
            path={routes.eventAttendance}
            element={<AttendanceRedirectRoutes />}
          />
          <Route
            path={routes.eventAttendanceQr}
            element={<AttendanceQrCodeRoutes />}
          />

          <Route path={routes.event} element={<AppWithAdminBanner />}>
            <Route element={<AppWithEventSidebar />}>
              <Route index element={<AboutRoutes />} />
              <Route
                path={routes.eventAttendees}
                element={<AttendeesRoutes />}
              />
              <Route path={routes.eventInsights} element={<InsightRoutes />} />
              <Route path={routes.eventAbout} element={<AboutRoutes />} />
            </Route>
          </Route>

          <Route path="*" element={<div>404</div>} />
        </Routes>
      </BrowserRouter>
    </WithSuspense>
  )
}
