import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Flex, HStack } from '@chakra-ui/react'

import { FeedbackSidebarInfo } from '~/app/components/FeedbackSidebarInfo'
import { ContentContainerStyle } from '~/app/styles'

import { useDesktopToast } from '~hooks/useDesktopToast'
import { Sidebar } from '~components/Sidebar'

import { ReactComponent as iconLogo } from '../icon-logo.svg'
import { ReactComponent as nameLogo } from '../name-logo.svg'

export const AppWithSidebar = (): JSX.Element => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 2)
  }, [])

  useDesktopToast()

  return (
    <HStack
      flex={1}
      alignItems="left"
      spacing="0px"
      sx={{
        position: 'relative',
        maxWidth: '100vw',
        overflowX: 'clip',
        borderTop: 'solid',
        borderTopWidth: '1px',
        borderColor: '#BABECB',
      }}
    >
      <Sidebar
        nameLogo={nameLogo}
        iconLogo={iconLogo}
        navigations={[]}
        // navigations={[
        //   {
        //     path: [routes.myEvents, routes.index],
        //     icon: BiCalendarEvent,
        //     name: 'My Events',
        //   },
        // ]}
        // extraSection={{
        //   title: 'Resources for you',
        //   navigations: [
        //     {
        //       name: 'All Events',
        //       path: routes.allEvents,
        //     },
        //     {
        //       name: 'Participants',
        //       path: routes.eventsParticipants,
        //     },
        //   ],
        // }}
        renderInfoTopComponent={({ isCollapsed }: { isCollapsed: boolean }) => (
          <FeedbackSidebarInfo isCollapsed={isCollapsed} />
        )}
      />
      <Flex sx={ContentContainerStyle}>
        <Outlet />
      </Flex>
    </HStack>
  )
}
