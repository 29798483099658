import { extendTheme } from '@chakra-ui/react'

import { theme } from '.'

export const engageTheme = extendTheme(theme, {
  fonts: {
    heading: `'Plus Jakarta Sans', sans-serif`,
    body: `'Plus Jakarta Sans', sans-serif`,
  },
})
