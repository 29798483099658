import { MdClose } from 'react-icons/md'
import { Box, Button, HStack, Icon, Text } from '@chakra-ui/react'

export type tag = {
  text: string
  color?: string
  bgColor?: string
}

interface TagsProps {
  text: string
  color?: string
  backgroundColor?: string
  removable?: boolean
}

const TagsStyle = {
  padding: '4px 8px',
  borderRadius: '50px',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
  fontFamily: `'Inter', sans-serif`,
  minWidth: 'fit-content',
  width: 'fit-content',
  height: 'fit-content',
}

export const Tags = ({
  text,
  color,
  backgroundColor,
  removable = false,
}: TagsProps) => {
  return (
    <Box
      as={removable ? Button : Box}
      sx={{
        ...TagsStyle,
        '&:hover': { backgroundColor },
      }}
      color={color ?? '#5389EF'}
      bg={backgroundColor ?? '#E7EFFC'}
    >
      <HStack spacing="8px">
        <Text>{text}</Text>
        {removable && <Icon as={MdClose} />}
      </HStack>
    </Box>
  )
}
