import {
  PRIMARY_TEXT_COLOUR,
  SIDEBAR_PADDING_X,
  SIDEBAR_WIDTH,
} from '~constants/styles'

export const FeedbackInfoStyle = {
  position: 'relative',
  justifyContent: 'center',
  display: 'flex',
}

export const FeedbackInfoContainerStyle = {
  padding: '16px',
  background:
    'linear-gradient(360deg, rgba(239, 245, 255, 0.85) 0%, #F5FAFA 100%)',
  borderRadius: '8px',
  width: `${SIDEBAR_WIDTH - 2 * SIDEBAR_PADDING_X}px`,
  minWidth: `${SIDEBAR_WIDTH - 2 * SIDEBAR_PADDING_X}px`,
}

export const FeedbackInfoTitleStyle = {
  color: '#272D41',
  fontSize: '20px',
  fontWeight: 600,
  textAlign: 'left',
  width: 'full',
}

export const FeedbackInfoCopyStyle = {
  color: PRIMARY_TEXT_COLOUR,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  fontFamily: `'Inter', sans-serif`,
  textAlign: 'left',
}
