import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  FormControl,
  HStack,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Input } from '@opengovsg/design-system-react'
import { z } from 'zod'

import { OTP_LENGTH } from '~shared/constants/otp'

import { PrimaryButton } from '~components/Buttons'
import { ErrorMessage } from '~components/Forms/ErrorMessage'
import { InfoBox } from '~components/InfoBox'

import { InputStyle, LabelStyle } from '~features/auth/styles'

import { ResendOtpButton } from './ResendOtpButton'

const schema = z.object({
  token: z
    .string()
    .trim()
    .min(1, 'OTP is required.')
    .regex(/^[0-9A-Z\b]+$/, {
      message: 'Only uppercase letters and numbers are allowed.',
    })
    .length(OTP_LENGTH, `Please enter a ${OTP_LENGTH} character OTP.`),
})

export type OtpFormInputs = {
  token: string
}

interface OtpFormProps {
  otpPrefix: string
  email: string
  onSubmit: (inputs: OtpFormInputs) => Promise<void>
  onResendOtp: () => Promise<void>
}

export const OtpForm = ({
  otpPrefix,
  email,
  onSubmit,
  onResendOtp,
}: OtpFormProps): JSX.Element => {
  const [filled, setFilled] = useState(false)
  const [resendOtpCount, setResendOtpCount] = useState(0)

  const { handleSubmit, register, formState, setError, setValue } =
    useForm<OtpFormInputs>({
      resolver: zodResolver(schema),
    })

  const onSubmitForm = async (inputs: OtpFormInputs) => {
    return onSubmit(inputs).catch((e) => {
      setError('token', { type: 'server', message: e.json.message })
    })
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitForm)}>
      <FormControl isRequired isInvalid={!!formState.errors.token} mb="2.5rem">
        <VStack spacing="16px" alignItems="left">
          <VStack spacing="12px" alignItems="left">
            <Text sx={LabelStyle}>
              {`Enter OTP sent to ${email.toLowerCase()}`}
            </Text>
            <InputGroup>
              <InputLeftAddon id="prefix">{otpPrefix}-</InputLeftAddon>
              <Input
                type="text"
                maxLength={OTP_LENGTH}
                inputMode="numeric"
                autoComplete="one-time-code"
                autoFocus
                sx={InputStyle}
                {...register('token')}
                onChange={(e) => {
                  setFilled(e.target.value.length > 0)
                  return register('token').onChange(e)
                }}
              />
            </InputGroup>
            <ErrorMessage>{formState.errors.token?.message}</ErrorMessage>
          </VStack>
          {resendOtpCount > 1 && (
            <InfoBox>
              OTP might be delayed due to government email traffic. Try again
              later or contact us if issue persists.
            </InfoBox>
          )}
        </VStack>
      </FormControl>
      <HStack justifyContent="left">
        <PrimaryButton
          isDisabled={!filled || !!formState.errors.token?.message}
          isLoading={formState.isSubmitting}
          type="submit"
        >
          Login
        </PrimaryButton>
        <ResendOtpButton
          onResendOtp={() => {
            setResendOtpCount(resendOtpCount + 1)
            setValue('token', '')
            return onResendOtp()
          }}
        />
      </HStack>
    </form>
  )
}
