export type GrowthbookFeatureValue =
  | string
  | number
  | JSON
  | boolean
  | undefined

export enum GrowthbookFeatures {
  defaultMaskNric = 'default-mask-nric',
  manualAttendanceByNric = 'manual-attendance-by-nric',
  adminBanner = 'admin-banner',
}

export type GrowthbookFeaturesMap = {
  [GrowthbookFeatures.defaultMaskNric]: boolean
  [GrowthbookFeatures.manualAttendanceByNric]: boolean
  [GrowthbookFeatures.adminBanner]: string
  [key: string]: GrowthbookFeatureValue // used to define default types
}
